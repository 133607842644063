<template>
  <v-card
    rounded="lg"
    v-if="store"
    >
    <v-card-title
      class="justify-center"
      >
      Nuestra tienda
    </v-card-title>

    <v-card-text>
      <div
        class="d-flex"
        >
        <search-products
          :store="store"
          ></search-products>

        <list-categories
          :store="store"
          ></list-categories>
      </div>

      <div
        v-if="store.aboutUs.body"
        class="mt-3"
        >
        <v-card
          @click="expand = !expand"
          :ripple="false"
          outlined
          class="mt-3 rounded-lg"
          >
          <v-card-title
            class="secondary--text py-2"
            >
            Sobre nosotros
            <v-icon 
              color="secondary"
              style="position: absolute; right: 16px"
              >
              {{ expand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-card-title>

          <v-expand-transition>
            <v-card-text
              v-if="expand"
              class="text--primary"
              v-html="store.aboutUs.body"
              >
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </div>

      <div
        v-if="false && header.banners.length"
        class="mb-3"
        >
        <v-carousel
          class="rounded-0"
          hide-delimiters
          cycle
          :show-arrows="!$vuetify.breakpoint.mobile"
          :height="header.height || 200"
          >
          <v-carousel-item
            v-for="banner in header.banners"
            :src="url + banner.imageUrl"
            ></v-carousel-item>
        </v-carousel>
      </div>

      <div
        class="px-2"
        >
        <template
          v-for="category in categories"
          >
          <recommendations
            class="mt-2"
            :head="category.name"
            :category="category"
            :store="store"
            ></recommendations>
        </template>

        <template
          v-if="products.length"
          >
          <div
            class="text-subtitle-1 ml-2 font-weight-medium d-flex justify-space-between py-1"
            >
            {{ categories.length ? 'Más productos' : 'Nuestros productos' }}
          </div>
          <v-slide-group
            >
            <template v-slot:prev="{ on, attrs }">
              <v-btn
                fab
                x-small
                v-bind="attrs"
                v-on="on"
                >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:next="{ on, attrs }">
              <v-btn
                fab
                x-small
                v-bind="attrs"
                v-on="on"
                >
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </template>
            <v-slide-item
              v-for="product in products"
              :key="product.id"
              >
              <thumb 
                class="mr-1"
                :store="store"
                :slide="true"
                :content="product"
                ></thumb>
            </v-slide-item>
          </v-slide-group>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex' 
import { PublicStore } from '@/graphql/queries/stores'
import { CategoriesWithProducts, UncategorizedProducts } from '@/graphql/queries/categories'

import Thumb from '@/components/Thumb'
const Recommendations = () => import('@/components/recommendations/Recommendations')
const CarouselProduct = () => import('@/components/profiles/stores/CarouselProduct')
const SearchProducts = () => import('@/components/products/Search')
const ListCategories = () => import('@/components/categories/List')

export default {
  data () {
    return {
      categories: [],
      featured: [],
      products: [],
      expand: false,
      header: null,
      store: null
    }
  },

  props: {
    profile: {
      type: Object,
      default: true
    }
  },

  components:{
    Recommendations, CarouselProduct, Thumb, SearchProducts, ListCategories
  },

  created () {
    this.fetchStore ()
  },

  methods: {
    openModal () {
      this.$refs.productDetail.dialog = true
    },

    fetchStore () {
      this.$apollo.query({
        query: PublicStore,
        variables: {
          slug: this.profile.profilableId
        }
      }).then ( res => {
        this.store = res.data.publicStore
        this.fetchCategories ()
        this.uncategorizedProducts ()
      })
    },

    uncategorizedProducts () {
      this.$apollo.query({
        query: UncategorizedProducts,
        variables: {
          storeId: this.store.id
        }
      }).then ( res => {
        this.products = res.data.uncategorizedProducts
      })
    },

    featuredProducts () {
      this.$apollo.query({
        query: Featured,
        variables: {
          storeId: this.store.id
        }
      }).then ( res => {
        this.featured = res.data.featuredProducts
      })
    },

    fetchCategories () {
      this.$apollo.query({
        query: CategoriesWithProducts,
        variables: {
          storeId: this.store.id
        }
      }).then ( res => {
        this.categories = res.data.categoriesWithProducts
      })
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'url'])
  }
}
</script>

<style scoped>
>>>.v-slide-group__content {
  margin-bottom: 4px
}

>>>.v-card--link:before {
  background: none;
}

>>>.v-slide-group__prev {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
}

>>>.v-slide-group__next {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 2;
}

>>>.v-slide-group__next > i {
  background: white;
  border-radius: 50%;
  padding: 4px
}

>>>.v-slide-group__prev > i {
  background: white;
  border-radius: 50%;
  padding: 4px
}
</style>
